// Stimulus
// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// glue code for javascript without stimulus (https://viewcomponent.org/guide/javascript_and_css.html)

function importAll(r) {
    r.keys().forEach(r)
}

importAll(require.context("../../components", true, /component\.js$/))